<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>

    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Staff Management</h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <router-link :to="{ name: 'UserAdd' }">
        <v-btn rounded color="primary" class="mb-5" x-small large>
          <v-icon color="white" dark>mdi-plus</v-icon
          ><span style="color: white">Add User</span>
        </v-btn>
      </router-link>

      <div>
        <v-row>
          <v-col cols="6">
            <v-pagination
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="6">
            <b-form-input
              class="mt-6"
              style="float: right; width: 300px"
              placeholder="Search"
              v-model="search_input"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">User</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Address</th>
                    <th scope="col">Total role</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(user, i) in users">
                    <tr :key="i">
                      <th scope="row" style="vertical-align: middle">
                        {{ i + 1 }}
                      </th>
                      <!-- <td>
                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{user.name}}</span>
                      </td> -->
                      <td>
                        <div class="d-flex">
                          <v-img
                            v-if="user.avatar"
                            :src="user.avatar"
                            max-width="70"
                            max-height="70"
                            class="rounded-lg mt-1"
                          ></v-img>
                          <v-img
                            v-if="user.avatar == null"
                            src="media/users/default.jpg"
                            max-width="50"
                            max-height="50"
                            class="rounded-lg mt-1"
                          ></v-img>
                          <div class="ml-2">
                            <p
                              class="text-dark-75 font-weight-bolder d-block font-size-lg mb-1"
                            >
                              {{ user.name }}
                            </p>
                            <p
                              class="text-muted font-weight-normal d-block font-size-lg"
                            >
                              {{ user.email }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ user.phone }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ user.address }}</span
                        >
                      </td>
                      <td>
                        <div>
                          <span
                            class="label label-lg label-light-warning label-inline"
                            >{{ user.roles.data.length }}</span
                          >
                          <!-- <v-icon color="#ffc107b8" class="mb-1">mdi-tooltip</v-icon> -->
                        </div>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'UserEdit',
                                  params: { id: user.id },
                                }"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-square-edit-outline</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Edit User</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeleteUser(user.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete User</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!users.length">
                    <tr>
                      <td style="text-align: center" colspan="7">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";
import ApiService from "@/service/api.service";
export default {
  name: "UserList",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      users: [],
      search_input: "",
    };
  },
  watch: {
    "data_pagination.current_page": function (page) {
      this.getAllUser(page, this.search_input);
    },
    search_input: function (val) {
      this.actionSearchUser(1, val);
    },
  },
  created() {
    this.getAllUser();
  },
  methods: {
    async getAllUser(page = 1, query = "") {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/user?page=" + page + "&name=" + query
        );
        if (res.status == 200) {
          this.users = res.data.data;
          this.data_pagination = res.data.meta.pagination;
          vm.is_call_api = false;
        }
      } catch (e) {
        vm.is_call_api = false;
        console.log(e);
      }
    },
    btnDeleteUser(id_user) {
      let self = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/user/" + id_user)
            .then(function (res) {
              if (res.status == 202) {
                self.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                self.getAllUser();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    actionSearchUser: _.debounce(function (page = 1, query = "") {
      this.getAllUser(page, query);
    }, 1200),
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
